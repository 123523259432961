import request from './request'

// 获取用户基本信息
export interface BasicDataDto {
    notice: {
        title: string
        content: string
    }
    person: {
        active: number
        monthCompare: number
    }
    service: {
        avatar: string
        mobile: string
        name: string
    }
    wallet: {
        balance: number
        warning: string
    }
}

export interface RiskAnalysisDto {
  rank: string
  score: number
  report_num: number
  report_money: number
  pay_ratio: number
  premium_ratio: number
  advise: string[]
}

export function getBasicData (): Promise<BasicDataDto> {
    return request.get('/home/data')
}

export function getRiskAnalysisData (): Promise<RiskAnalysisDto> {
    return request.get('/client/riskAnalysis')
}

export interface CommonlyFileDto {
    name: string
    type: string
    url: string
}
export function getCommonlyFile (): Promise<CommonlyFileDto[]> {
    return request.get('/home/files')
}

// 获取活动信息
export function getNewActivity (params: {
    source: 'pc'
}): Promise<{ image: string; url: string }> {
    return request.get('/home/popup', {
        params
    })
}

export function getHotline (): Promise<{ number: string; }> {
    return request.get('/help/hotline')
}

export function getAd (params: { source: 'pc' }): Promise<[]> {
    return request.get('/home/ad', { params })
}
